:root {
  --black: #171321;
  --dkblue: #012840;
  --dkgreenblue: #0f4c59;
  --sdkgreenblue: #196a73;
  --sdkgreen: #5ba691;
  --dkcyan: #014039;
  --brightblue: #0096ff;
  --white: #f7f8fa;
  --liyellow: #faf566;
  --dkyellow: #f8f01c;
  --font-size: 1.3rem;
  --mono: "Overpass", monospace;
  --sans: "Chivo", sans-serif;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  font-family: var(--sans);
  background-color: var(--dkblue);
  color: var(--white);
  font-size: var(--font-size);
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  margin: 0;
}

a {
  color: var(--liyellow);
}

a:hover {
  color: var(--dkyellow);
  text-decoration: none;
}

img {
  width: 100%;
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.section-sdkgreenblue {
  background-color: var(--sdkgreenblue);
}

.section-dkcyan {
  background-color: var(--dkcyan);
}

.gradient {
  background: linear-gradient(90deg, #b22222 0%, #faf566 100%);
  height: 2px;
}

#intro {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem 10rem;
}

#intro p {
  font-size: 1rem;
  line-height: 1.5;
}

#intro .name {
  font-family: var(--mono);
}

.name span {
  font-family: var(--sans);
  color: var(--sdkgreen);
  font-size: 4rem;
  font-weight: normal;
  display: block;
}

#contact {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 3rem 0;
}

#contact p {
  font-size: 1rem;
}

#contact p:last-child {
  margin-top: 3rem;
}

nav {
  font-family: var(--mono);
  padding: 4rem 1rem;
  font-size: 80%;
}

nav h1 a {
  font-family: var(--sans);
}

nav ul {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

nav li:first-child {
  text-align: center;
  flex-basis: 100%;
}

nav a {
  color: var(--white);
  text-decoration: none;
  display: block;
}

nav a:hover {
  color: var(--liyellow);
}

.nav-align-contents {
  justify-content: center;
  align-items: center;
  display: flex;
}

nav a:hover .nav--icons {
  filter: invert(97%) sepia(19%) saturate(7327%) hue-rotate(314deg) brightness(118%) contrast(110%);
}

.nav--icon-main {
  width: 2.5rem;
  height: 2.5rem;
}

.nav--icons {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 3px;
}

.filter-firebrick {
  filter: invert(18%) sepia(69%) saturate(2879%) hue-rotate(347deg) brightness(96%) contrast(92%);
}

.button {
  background-color: var(--dkgreenblue);
  font-family: var(--mono);
  color: var(--white);
  border-radius: 5px;
  padding: .5rem;
  text-decoration: none;
}

.button:hover {
  color: var(--white);
  background-color: #b22222;
}

footer {
  text-align: center;
  padding: 4rem 0;
}

footer ul {
  flex-flow: wrap;
  justify-content: center;
  gap: 3rem;
  margin: 2rem 0;
  padding: 0;
  font-size: 3rem;
  list-style-type: none;
  display: flex;
}

.footer--icons {
  width: 3rem;
  height: 3rem;
}

a:hover .footer--icons {
  filter: invert(97%) sepia(19%) saturate(7327%) hue-rotate(314deg) brightness(118%) contrast(110%);
}

#projects {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

#projects h2 {
  margin-bottom: 3.75rem;
  font-size: 2.5rem;
}

#projects h3 {
  color: var(--brightblue);
}

#projects h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}

.blackbox {
  background-color: var(--dkblue);
  color: var(--white);
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

#projects ul {
  flex-flow: wrap;
  justify-content: flex-start;
  gap: 1rem;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  list-style-type: none;
  display: flex;
}

#projects img {
  border-left: 1px solid var(--brightblue);
  border-top: 1px solid var(--brightblue);
  border-radius: 25px;
  margin: 2rem 0 4rem;
  padding: 1rem;
}

@media (width >= 550px) {
  article {
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
    display: grid;
  }

  #projects img {
    grid-area: 1 / 1 / 2 / 6;
  }

  .text {
    text-align: right;
    order: 2;
    grid-area: 1 / 5 / 2 / 11;
  }

  #projects ul {
    justify-content: flex-end;
  }

  #projects .reverse .text {
    text-align: left;
    order: 2;
    grid-column: 1 / 7;
  }

  #projects .reverse img {
    grid-area: 1 / 6 / 2 / 11;
  }

  #projects .reverse ul {
    justify-content: flex-start;
  }
}

#about {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

#about h2 {
  margin-bottom: 3.75rem;
  font-size: 2.5rem;
}

#about h3 {
  color: var(--brightblue);
  margin: 1rem;
}

#about h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}

#about ul {
  flex-flow: wrap;
  justify-content: flex-start;
  gap: 1rem;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  list-style-type: none;
  display: flex;
}

#about img {
  border-left: 1px solid var(--brightblue);
  border-top: 1px solid var(--brightblue);
  border-radius: 25px;
  margin: 2rem 0 4rem;
  padding: 1rem;
}

#resume {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 4rem;
}

#resume a {
  color: #00f;
  text-decoration: underline;
}

#resume h1 {
  color: var(--white);
  font-size: 3rem;
  font-family: var(--mono);
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

#resume ul {
  margin-top: 0;
  margin-bottom: .5rem;
}

#resume li {
  padding-right: .7rem;
}

.resume--subheader {
  justify-content: space-between;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.resume--subheader p {
  margin: 0;
}

@media (width >= 550px) {
  #about img {
    grid-area: 1 / 1 / 2 / 6;
  }

  #about ul {
    justify-content: flex-end;
  }
}

@media (width >= 850px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  nav li:first-child {
    text-align: left;
    flex-basis: auto;
    margin-right: auto;
  }
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.sr-only-focusable:focus, .sr-only-focusable:active {
  clip: auto !important;
  clip-path: none !important;
  white-space: normal !important;
  width: auto !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
}

.accordion {
  width: 90%;
  margin: 0 auto;
}

@media (width >= 850px) {
  .accordion {
    width: 97%;
  }
}

.accordion--item {
  background-color: var(--white);
  border-radius: .4rem;
  margin-bottom: 1rem;
}

.accordion--header {
  color: var(--black);
  text-align: left;
  font-size: 2rem;
  font-family: var(--mono);
  background: none;
  border: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .6rem .6rem 0;
  display: flex;
}

.accordion--header:after {
  content: "";
  background-image: url("downChevron.138d0619.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  width: 1rem;
  height: 1rem;
  transition: transform .3s ease-in-out;
  display: block;
}

.accordion--header.open:after {
  transform: rotate(-180deg);
}

.accordion--content {
  color: var(--black);
  padding-bottom: .5rem;
  font-size: 1rem;
  line-height: 1.75;
}

.accordion--collapse, .accordion--open {
  overflow: hidden;
}
/*# sourceMappingURL=index.7ab9a949.css.map */
