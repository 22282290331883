/* variables declared here - these are the colors for my pages, as well as the font stacks and sizes. */
:root {
  --black: #171321;
  --dkblue: #012840;
  --dkgreenblue: #0f4c59;
  --sdkgreenblue: #196a73;
  --sdkgreen: #5ba691;
  --dkcyan: #014039;
  --brightblue: #0096ff;
  --white: #f7f8fa;
  --liyellow: #faf566;
  --dkyellow: #f8f01c;
  --font-size: 1.3rem;
  --mono: "Overpass", monospace;
  --sans: "Chivo", sans-serif;
  /* s = semi dk = dark  li = light */
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--sans);
  background-color: var(--dkblue);
  color: var(--white);
  font-size: var(--font-size);
}

h1,
h2,
h3 {
  margin: 0;
}

a {
  color: var(--liyellow);
}

a:hover {
  color: var(--dkyellow);
  text-decoration: none;
}

img {
  width: 100%;
}

/* hiding scroll bar*/

.hide-scroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar {
  /* WebKit */
  display: none;
}

/* background color divs */

.section-sdkgreenblue {
  background-color: var(--sdkgreenblue);
}

.section-dkcyan {
  background-color: var(--dkcyan);
}

.gradient {
  background: linear-gradient(
    90deg,
    rgba(178, 34, 34, 1) 0%,
    rgba(250, 245, 102, 1) 100%
  );
  height: 2px;
}

/* intro */

#intro {
  padding: 4rem 1rem 10rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

#intro p {
  font-size: 1rem;
  line-height: 1.5;
}

#intro .name {
  font-family: var(--mono);
}

.name span {
  font-family: var(--sans);
  font-size: 4rem;
  color: var(--sdkgreen);
  font-weight: normal;
  display: block;
}

/* contact */

#contact {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 3rem 0;
}

#contact p {
  font-size: 1rem;
}

#contact p:last-child {
  margin-top: 3rem;
}

/* navbar */

nav {
  font-family: var(--mono);
  font-size: 80%;
  padding: 4rem 1rem;
}

nav h1 a {
  font-family: var(--sans);
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

nav li:first-child {
  flex-basis: 100%;
  text-align: center;
}

nav a {
  color: var(--white);
  text-decoration: none;
  display: block;
}

nav a:hover {
  color: var(--liyellow);
}

.nav-align-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a:hover .nav--icons {
  filter: invert(97%) sepia(19%) saturate(7327%) hue-rotate(314deg)
    brightness(118%) contrast(110%);
}

.nav--icon-main {
  width: 2.5rem;
  height: 2.5rem;
}
.nav--icons {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 3px;
}

.filter-firebrick {
  filter: invert(18%) sepia(69%) saturate(2879%) hue-rotate(347deg)
    brightness(96%) contrast(92%);
}

.button {
  background-color: var(--dkgreenblue);
  padding: 0.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-family: var(--mono);
  color: var(--white);
}

.button:hover {
  color: var(--white);
  background-color: firebrick;
}

footer {
  text-align: center;
  padding: 4rem 0;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
  font-size: 3rem;
}

.footer--icons {
  width: 3rem;
  height: 3rem;
}

a:hover .footer--icons {
  filter: invert(97%) sepia(19%) saturate(7327%) hue-rotate(314deg)
    brightness(118%) contrast(110%);
}

/* projects section */
#projects {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

#projects h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}

#projects h3 {
  color: var(--brightblue);
}

#projects h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}

.blackbox {
  background-color: var(--dkblue);
  padding: 1rem;
  border-radius: 10px;
  color: var(--white);
  font-size: 1rem;
  line-height: 1.5;
}

#projects ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1rem;
}

#projects img {
  margin: 2rem 0 4rem 0;
  border-left: 1px solid var(--brightblue);
  border-top: 1px solid var(--brightblue);
  border-radius: 25px;
  padding: 1rem;
}

@media (min-width: 550px) {
  article {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
  }

  #projects img {
    grid-column: 1/6;
    grid-row: 1/2;
  }

  .text {
    grid-column: 5/11;
    order: 2;
    text-align: right;
    grid-row: 1/2;
  }

  #projects ul {
    justify-content: flex-end;
  }

  #projects .reverse .text {
    grid-column: 1/7;
    order: 2;
    text-align: left;
  }

  #projects .reverse img {
    grid-column: 6/11;
    grid-row: 1/2;
  }

  #projects .reverse ul {
    justify-content: flex-start;
  }
}

/* about section */
#about {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

#about h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}

#about h3 {
  color: var(--brightblue);
  margin: 1rem;
}

#about h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}

#about ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1rem;
}

#about img {
  margin: 2rem 0 4rem 0;
  border-left: 1px solid var(--brightblue);
  border-top: 1px solid var(--brightblue);
  border-radius: 25px;
  padding: 1rem;
}

#resume {
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 4rem;
}

#resume a {
  color: blue;
  text-decoration: underline;
}

#resume h1 {
  color: var(--white);
  font-size: 3rem;
  font-family: var(--mono);
  text-align: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

#resume ul {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}

#resume li {
  padding-right: 0.7rem;
}

.resume--subheader {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-right: 1rem;
  padding-left: 1rem;
}
.resume--subheader p {
  margin: 0;
}

@media (min-width: 550px) {
  #about img {
    grid-column: 1/6;
    grid-row: 1/2;
  }

  #about ul {
    justify-content: flex-end;
  }
}

@media (min-width: 850px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  nav li:first-child {
    flex-basis: auto;
    text-align: left;
    margin-right: auto;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.accordion {
  margin: 0 auto;
  width: 90%;
}

@media (min-width: 850px) {
  .accordion {
    width: 97%;
  }
}

.accordion--item {
  background-color: var(--white);
  border-radius: 0.4rem;
  margin-bottom: 1rem;
}

.accordion--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  color: var(--brightblue);
  background: transparent;
  padding: 0.6rem 0.6rem 0rem 0.6rem;
  width: 100%;
  text-align: left;
  font-size: 2rem;
  font-family: var(--mono);
  color: var(--black);
}

.accordion--header::after {
  content: "";
  display: block;
  border: none;
  width: 1rem;
  height: 1rem;
  background-image: url("../icons/downChevron.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transition: transform 0.3s ease-in-out;
}

.accordion--header.open::after {
  transform: rotate(-180deg);
}

.accordion--content {
  color: var(--black);
  font-size: 1rem;
  line-height: 1.75;
  padding-bottom: 0.5rem;
}

.accordion--collapse {
  overflow: hidden;
}

.accordion--open {
  overflow: hidden;
}
